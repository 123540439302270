import React, {useRef, useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    Container,
    Button,
    ListItem,
    ListItemText,
    Divider,
    Backdrop,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import HTMLViewer from './components/HTMLViewer';
import {getPDFs, convertPDF, deletePdfHTML} from './pdf.api';
import toast from 'react-hot-toast';
import {useAuth} from '../../contexts/AuthContext';
import "./components/HTMLViewer.css";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    pdfItem: {
        ' .MuiListItemText-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const ExtractPDF = () => {
  const { auth, setAuthCtx } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [curPDF, setCurPDF] = useState({
      id: '',
      totalPages: 0,
  });
  // const filenameRef = useRef(null);
  const filepathRef = useRef(null);
  const [pdfList, setPDFList] = useState([]);
  const [filtering, setFiltering] = useState('')
  const [includeImages, setIncludeImages] = useState(true);
  const [filename, setFilename] = useState('')
  const [filepath, setFilepath] = useState('')

  const getAllPDFs = async () => {
    // Enable loading
    setLoading(true);

    try{
      setLoading(true);
      const response = await getPDFs(auth.token)
      toast.success(response.data.message);
      setPDFList(response.data.pdfs);
      if( response.data.pdfs.length > 0 ){
        const pdf = response.data.pdfs[0]
        setCurPDF({ ...pdf });
      }else{
        setCurPDF({ id: '', totalPages: 0})
      }   
      setLoading(false);
    }catch(err){
      console.log("failed getAllPDFs", err)
      let errMsg = err.response?err.response.message:'Something went wrong';
      if( errMsg == null )
        errMsg = err.response?err.response.data:'Something went wrong';
      console.log("errMsg ", errMsg)
      toast.error(errMsg);
      redirectToLogin()
    }
  };

  useEffect(() => {
      // console.log("getAllPDFsgetAllPDFs")
      getAllPDFs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
          // See: https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook

  const redirectToLogin = () => {
    history.push('/')
    setAuthCtx({ token: null})
  }



    // ----------------------
    // Handlers
    // ----------------------

    const onClickPDF = (pdf) => {
      setCurPDF({ ...pdf });
    };

    const onProcessPDF = async (event) => {
      try{
        if ( filename.length === 0) {
          toast.error('Select the file');
          return;
        }
        // if ( !filepathRef.current ) {
        //     toast.error('Input the file name');
        //     return;
        // }

        if ( filepath.length === 0 ) {
          toast.error('Input the file name');
          return;
        }

        // Enable loading
        setLoading(true);
        const res = await convertPDF(filepath, filename, auth.token, includeImages)
        toast.success(res.data.message);

        // Reload list
        await getAllPDFs();
      }catch(err){
        console.log("err ", err)
        toast.error(err.message)
      }

      // Remove loading
      setLoading(false);
      event.target.value = null;    
      filepathRef.current.value = '' // Clear the current path 
      setFilename('')
      
    };

    const onDelete = async (pdfId) => {
      try{
        // Enable loading
        setLoading(true);
        const res = await deletePdfHTML(auth.token, pdfId)
        toast.success(res.data.message);

        // Reload list
        await getAllPDFs();
      }catch(err){
        console.log('---deletePdfError---', err)
        toast.error(err.message)
      }

      // Remove loading
      setLoading(false);
    }

    let pdfListLocal = null
    if( filtering.length >= 3){
      pdfListLocal = pdfList.filter( pdf => pdf.name.toLowerCase().includes( filtering.toLowerCase() ))
    }else{
      pdfListLocal = [...pdfList]
    }
    

    return (<div className="d-flex flex-column pb-3" style={{ background: '#DDD', height: '100vh'}}>
              <div className="w-100" style={{ background: '#3f51b5', color: '#DDD', height: '60px'}}>
                  <div className='p-3 d-flex align-items-center justify-content-between h-100 fw-bold' >
                      <div role="button" style={{ fontSize: '20px'}}>FreeFlow</div>
                      <button type="button" className="btn btn-secondary"
                      onClick={() => redirectToLogin()}>Logout</button>
                  </div>
              </div>

              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
              </Backdrop>

              <Container className="flex-grow-1 d-flex flex-column pb-3" style={{ background: '#FFF', borderRadius: '10px', marginTop: '4em', paddingTop: '0.02em'}}>

                
                <Typography  component="h4" variant="h4" className={classes.header}>
                    Extract PDF and convert to HTML                    
                </Typography>
                <hr></hr>
                

                <Grid container className="d-flex flex-grow-1" spacing={2}>
                    <Grid item md={4} className="d-flex flex-column h-100">
                        <div>
                          <label className="form-label">Title</label>
                          <input type="text" className="form-control" 
                              placeholder="Introduce Title"
                              value={filename}
                              onChange={(e) => setFilename(e.target.value)}></input>                             
                        </div>
                        <div className='mt-2'>
                          <label className="form-label">File</label>

                          <input ref={filepathRef} className="form-control" type="file" accept="application/pdf"
                            onChange={(e) => setFilepath(e.target.files[0])}></input>                          
                        </div>
                        {/* <div className='mt-1 text-center'>
                          <FormControlLabel 
                            control={<Checkbox defaultChecked={includeImages}  color="primary" onChange={() => setIncludeImages(!includeImages)}/>} 
                            label="Include images" />
                        </div> */}
                        <div className='mt-3 text-center'>
                            <Button variant="contained" color="primary" onClick={onProcessPDF}>
                                Process PDF
                            </Button>
                        </div>
                        <div className='mt-3'>
                          <input type="text" className="form-control" 
                              placeholder="Filtering"
                              onChange={(e) => setFiltering(e.target.value)}></input>                             
                        </div>
                        <div className='mt-3  flex-grow-1' 
                          style={{ maxHeight: '100%', height: '0px', overflow: 'auto', border: '1px solid grey'}}
                          >
                            {pdfListLocal.map((pdf, idx) => (
                                <React.Fragment key={idx}>
                                    <ListItem button>
                                        <ListItemText
                                            primary={pdf.name}
                                            onClick={() => onClickPDF(pdf)}
                                            className={classes.pdfItem}
                                        />
                                    </ListItem>
                                    <Divider/>
                                </React.Fragment >
                            ))}
                        </div>
                    </Grid>
                    {curPDF.id.length>0?
                    <Grid item>
                      <HTMLViewer pdfId={curPDF.id} name={curPDF.name} totalPages={curPDF.totalPages} 
                        onDelete={(pdfId) => onDelete(pdfId)}/>
                    </Grid>
                    :null}
                </Grid>
            </Container>
        </div>);
};


export default ExtractPDF;

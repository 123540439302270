import React, { createContext, useState, useEffect, useContext } from 'react';

export const authContext = createContext({});


const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    token: null,
    zoomCount:1
  });

  const setAuthCtx = (data) => {
    setAuth({
      token: data.token,
      loading: false,
      zoomCount: data.zoomCount,
    });
  };

  useEffect(() => {
    //console.log('-----test---',JSON.parse(window.localStorage.getItem('freeflow-auth'))?.zoomCount);
    setAuth({
      loading: false,
      token: JSON.parse(window.localStorage.getItem('freeflow-auth'))?.token,
      zoomCount: JSON.parse(window.localStorage.getItem('freeflow-auth'))?.zoomCount ? JSON.parse(window.localStorage.getItem('freeflow-auth'))?.zoomCount : 1,
    });
  }, []);

  useEffect(() => {
    window.localStorage.setItem('freeflow-auth', JSON.stringify(auth));
  }, [auth]);




  return (
    <authContext.Provider value={{ auth, setAuthCtx, sergi: '123'}}>
      {children}
    </authContext.Provider>
  );
};



export const useAuth = () => useContext(authContext);
export default AuthProvider;


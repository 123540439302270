import React, {useRef, useEffect, useState} from 'react';
import {Button, Typography, Grid, Container} from '@material-ui/core';
import JoditEditor from "jodit-react";
import {makeStyles} from '@material-ui/core';
// import CKEditor from 'react-ckeditor-component';
import {toast} from 'react-hot-toast';
import qs from 'query-string';
import {useLocation} from 'react-router-dom';


import {useAuth} from '../../contexts/AuthContext';
import {getPageHtml, savePageHtml} from './pdf.api';
import './style-content.css';

const cheerio = require("cheerio");

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    ckEditorContent: {
        marginTop: theme.spacing(3),
    }
}));

const HTMLEditor = () => {
    const {auth} = useAuth();
    const classes = useStyles();
    const {pdfId, pageNum} = qs.parse(useLocation().search);
    const [htmlContent, setHTMLContent] = useState('');
    const editor = useRef(null);

    const config = {
        zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
        enableDragAndDropFileToEditor: true,
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 'calc(100vh - 180px)',
        minHeight: 100,
        debugLanguage: false,
        i18n: 'en',
        tabIndex: 0,
        toolbar: true,
        enter: 'P',
        useSplitMode: false,
        colorPickerDefaultTab: 'background',
        imageDefaultWidth: 100,
        disablePlugins: ['paste', 'stat'],
        events: {},
        textIcons: false,
        uploader: {
            insertImageAsBase64URI: true
        },
        placeholder: '',
        showXPathInStatusbar: false
    }

    useEffect(() => {
        if (pdfId && pageNum) {
            getPageHtml(pdfId, pageNum)
                .then((res) => {

                    // setHTMLContent(res.data);
                    //   console.log('-----ssssss------', document.getElementById("curDiv").innerText);
                    const $ = cheerio.load(res.data.html);
                    const data = $('#curDiv').html();
                    console.log('----sss---', data);
                    editor.current = data
                    setHTMLContent(data)
                })
                .catch((err) => console.log(err));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    // See: https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook


    if (!pdfId || !pageNum) return <h1>Error</h1>;

    // const onEditorChange = (newValue) => {
    //     setHTMLContent(newValue);
    // };

    const handleSave = async () => {
        savePageHtml(pdfId, pageNum, editor.current, auth.token)
            .then((res) => {
                toast.success(res.data.message);
            })
            .catch((err) => {
                console.log("err ", err)
                toast.error('Something went wrong')

            });
    };



    return (

        <Container>
            <Typography component="h1" variant="h5" className={classes.header}>
                Edit HTML
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSave}>
                Save HTML
            </Button>


            <Grid className={classes.ckEditorContent} xs={12}>

                <JoditEditor
                    ref={editor}
                    value={htmlContent}
                    config={config}
                    onBlur={(newContent) => editor.current = newContent} // preferred to use only this option to update the content for performance reasons
                    // onChange={(newContent) => {setHTMLContent(newContent)}}
                />
            </Grid>

        </Container>
    );
}
    ;

    export default HTMLEditor;

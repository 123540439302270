import axios from 'axios';
import {BACKEND_BASE_URL, PDF_BASE_URL} from '../../config/constants';

export const convertPDF = async (pdf, filename, authToken, includeImages) => {
    const formData = new FormData();
    formData.append('file', pdf);
    formData.append('filename', filename);
    formData.append('include_images', includeImages);

    return axios.post(`${BACKEND_BASE_URL}/pdf/convert`, formData, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            'content-type': 'multipart/form-data',
        },
    });
};

export const getPageHtml = async (pdfId, pageNum) =>
    axios({
        method: 'GET',
        url: `${PDF_BASE_URL}/v2/${pdfId}/${pageNum}`,
        headers: {
            'content-type': 'text/html',
        },
    });

export const savePageHtml = async (pdfId, pageNum, html, authToken) =>
    axios.post(
        `${BACKEND_BASE_URL}/pdf/${pdfId}/${pageNum}`,
        {html},
        {
            headers: {Authorization: `Bearer ${authToken}`},
        }
    );

export const getPDFs = async (authToken) =>
    axios.get(`${BACKEND_BASE_URL}/pdf`, {
        headers: {Authorization: `Bearer ${authToken}`},
    });


export const currentFramePdfContent = async (authToken, pdfId, curPage, zoomCount) => {
    return axios.get(`${BACKEND_BASE_URL}/pdf/${pdfId}/${curPage}`, {
        headers: {Authorization: `Bearer ${authToken}`},
    })
}
export const mergePageHtml = async (pdfId, pageNum, authToken) =>
    axios.post(
        `${BACKEND_BASE_URL}/pdf/${pdfId}/${pageNum}/merge`,
        {},
        { headers: {Authorization: `Bearer ${authToken}`}, }
    );

export const unmergePageHtml = async (pdfId, pageNum, authToken) =>
    axios.post(
        `${BACKEND_BASE_URL}/pdf/${pdfId}/${pageNum}/unmerge`,
        {},
        { headers: {Authorization: `Bearer ${authToken}`}, }
    );

export const getPageMergeInfo = async (pdfId, pageNum) =>
    axios({
        method: 'GET',
        url: `${PDF_BASE_URL}/${pdfId}/${pageNum}/merge`,
    });

export const deletePdfHTML = async (authToken,pdfId) =>{
    console.log('---delete-----');
    return axios.get(`${BACKEND_BASE_URL}/pdf/${pdfId}/1/delete`, {
        headers: {Authorization: `Bearer ${authToken}`},
    })
}



import React, {useState, useEffect} from 'react';
import {toast} from 'react-hot-toast';
import qs from 'query-string';
import {Slider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import {PDF_BASE_URL} from '../../../config/constants';
import {useAuth} from '../../../contexts/AuthContext';
//import {getPageMergeInfo, mergePageHtml, currentFramePdfContent, unmergePageHtml} from '../pdf.api';
import {mergePageHtml, unmergePageHtml} from '../pdf.api';
import "./HTMLViewer.css";
import { faUpRightFromSquare, faTrash, faPencil, faObjectGroup, 
    faObjectUngroup, faThumbTack, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../../../config/constants';

const useStyles = makeStyles((theme) => ({
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    link: {
        textAlign: 'center',
    },
}));


const AUDIO_GENERATION_STATUS = { IDLE: 'IDLE', ONGOING: 'ONGOING', COMPLETED: 'COMPLETED'}
const HTMLViewer = (props) => {
  const classes = useStyles();
  const [curPage, setCurPage] = useState(1);
  const [htmlContent, setHtmlContent] = useState("");
  const [zoomCount, setZoomCount] = useState(1.0);
  const [mergeInfo, setMergeInfo] = useState({ merged: [] });
  const [loadingMerge, setLoadingMerge] = useState(false);
  const [thumbnailModal, setThumbnailModal] = useState(false);
  const [generateAudioModal, setGenerateAudioModal] = useState(false);
  const [thumbnail, setThumbnail] = useState({
    title: '', text: '', img: ''
  });
  const [voiceId, setVoiceId] = useState("Arthur")
  const [languageCode, setLanguageCode] = useState("en-GB")
  const [languageList, setLanguageList] = useState([])
  const [voiceList, setVoiceList] = useState([])
  // const [audioGenerated, setAudioGenerated] = useState(false);
  const [audioGenerationStatus, setAudioGenerationStatus] = useState(AUDIO_GENERATION_STATUS.IDLE);
  const [pageViews, setPageViews] = useState(null);
  const {auth} = useAuth();
  const history = useHistory();

  const getCurrentFramePdfContent = async () => {
    try{
      const headers = {Authorization: `Bearer ${auth.token}`}
      const res = await axios.get(`${BACKEND_BASE_URL}/pdf/v2/${props.pdfId}/${curPage}`, { headers })
      let data = res.data.html
      if ((data === null) || (data === '')) return false;

      setThumbnail({
        title: res.data.thumbnail_title,
        text: res.data.thumbnail_text,
        img: res.data.thumbnail_img
      })
      setPageViews(res.data.page_views)
      // Set audio generated
      // setAudioGenerated( res.data.aws_s3_audio != null && res.data.aws_s3_audio.length > 0 )
      const isAudioCompleted = res.data.aws_s3_audio != null && res.data.aws_s3_audio.length > 0;      
      setAudioGenerationStatus( isAudioCompleted?AUDIO_GENERATION_STATUS.COMPLETED:AUDIO_GENERATION_STATUS.IDLE )
      // Set HTML Content
      setHtmlContent(data);
      // Set Merge Information
      setMergeInfo({ merged: res.data.merged } );
    }catch(err){
      console.error(err)
    }
  }
  
  // const getPDFData = async () => {
  //   try{
  //     const headers = {Authorization: `Bearer ${auth.token}`}
  //     const res = await axios.get(`${BACKEND_BASE_URL}/pdf/v2/${props.pdfId}/`, { headers })
  //     setThumbnail({
  //       title: res.data.thumbnail_title,
  //       text: res.data.thumbnail_text,
  //       img: res.data.thumbnail_img
  //     })
  //   }catch(err){
  //     console.error(err)
  //   }
  // }

  useEffect(() => {    
    getCurrentFramePdfContent()    
  }, [curPage, loadingMerge, props.pdfId]) // eslint-disable-line react-hooks/exhaustive-deps
                             // See: https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
  useEffect(() => {
    setCurPage(1);
    setZoomCount(1.0);
    //getPDFData()
    ( async () => {
      const headers = {Authorization: `Bearer ${auth.token}`}
      const response = await axios.get(`${BACKEND_BASE_URL}/pdf/v2/text2speech/voices/`, {headers})
      setLanguageList([ ...response.data ])
      const voiceList = response.data.find( item => item.LanguageCode === languageCode).voiceList
      setVoiceList(voiceList)

    })();

  }, [props.pdfId]); // eslint-disable-line react-hooks/exhaustive-deps


  // // TODO: Not really sure why this is needed
  // useEffect(() => {
  //   return () => {
  //     setZoomCount(1.0)
  //     getCurrentFramePdfContent()
  //   };
  // }, [curPage]); // eslint-disable-line react-hooks/exhaustive-deps
  //                // See: https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook



  // useEffect(() => {
  window.addEventListener('message', (e) => {
    console.log(e)
    let zoomType = e.data;

    if (zoomType === "zoomPlus") {
      const zoomCountLocal = zoomCount < 2?zoomCount + 0.1:1.9        
      setZoomCount(zoomCountLocal);
    } 
    if (zoomType === "zoomMinus") {
      const zoomCountLocal = zoomCount > 0.2?zoomCount - 0.1:0.3       
      setZoomCount(zoomCountLocal);    
    }
    if (zoomType === 'rs-play') {
      console.log('--sss----')
    }
  })


  // ----------------------
  // Handlers
  // ----------------------
  const onMerge = async () => {
    try{
      setLoadingMerge(true);
      const res = await mergePageHtml(props.pdfId, curPage, auth.token)

      toast.success(res.data.message);
      setLoadingMerge(false);

      // Update page
      getCurrentFramePdfContent()
    }catch(err){
      toast.error('Something went wrong')
    }        
  };
  const onUnmerge = async () => {
    try{
      setLoadingMerge(true);
      const res = await unmergePageHtml(props.pdfId, curPage, auth.token)

      toast.success(res.data.message);
      setLoadingMerge(false);

      // Update page
      getCurrentFramePdfContent()
    }catch(err){
      toast.error('Something went wrong')
    }   
  };
  const onOpen = () => {
      window.open(currentDisplayPDFPageLink, '_blank');        
  }
  const onEdit = () => {
      history.push(`/edit?${searchString}`)
  }
  const onThumbnail = () => {
    setThumbnailModal(true)
  }
  const onSaveThumbnail = async () => {
    try{
      const headers = {Authorization: `Bearer ${auth.token}`}
      const data = {
        thumbnail_title: thumbnail.title,
        thumbnail_text: thumbnail.text,
        thumbnail_img: thumbnail.img,
      }
      await axios.post(`${BACKEND_BASE_URL}/pdf/${props.pdfId}/${curPage}`, data, { headers })
      setThumbnailModal(false)
    }catch(err){
      console.error(err)
      alert(err)
    }
  }
  const onGenerateAudio = async () => {
    try{
      setAudioGenerationStatus(AUDIO_GENERATION_STATUS.ONGOING)
      setTimeout( () => setAudioGenerationStatus(AUDIO_GENERATION_STATUS.COMPLETED), 1000)


      const headers = {Authorization: `Bearer ${auth.token}`}
      const iframe = document.getElementById('iframe')
      //const textContent = iframe.contentWindow.document.getElementById('childDiv').textContent
      const textContent = iframe.contentWindow.document.getElementById('childDiv').innerText
      // const language_code = 'en-GB';
      const body = {text: textContent, voice_id: voiceId}
      //const body = {text: textContent, language_code: languageCode, voice_id: voiceId}
      await axios.post(`${BACKEND_BASE_URL}/pdf/v2/${props.pdfId}/${curPage}/text2speech`, body, {headers})

      setGenerateAudioModal(true)
      // setAudioGenerated(true)
    }catch(err){
      console.error(err)
      alert(err)
    }
  }



  const onChangeLanguageCode = (e) => {
    setLanguageCode(e.target.value)
    if( languageCode ){
      let voiceList = languageList.find( item => item.LanguageCode === e.target.value).voiceList
      setVoiceList(voiceList)
      setVoiceId(voiceList[0])
    }
  }



  // ----------------------
  // Rendering functions
  // ----------------------
  const isMergeAvailable = () => {
    if (curPage === 1) return false;
    if (mergeInfo.merged === undefined) return true;

    // Other cases
    let abovePage = curPage - 1;
    const isMergeAvailable = (mergeInfo.merged.includes(abovePage) && mergeInfo.merged.includes(curPage)) === false
    //console.log("isMergeAvailable - mergeInfo", mergeInfo)
    return isMergeAvailable
  }

  const isUnmergeAvailable = () => {
    //console.log("isUnmergeAvailable - mergeInfo", mergeInfo)
    if (curPage === 1) return false;
    if (mergeInfo.merged === undefined) return false;

    const isUnmergeAvailable = mergeInfo.merged.length > 0 && mergeInfo.merged[ mergeInfo.merged.length - 1 ] === curPage
    return isUnmergeAvailable
  }

  // ---------------------
  if (!props.pdfId) return <h4>No PDFs yet</h4>;
  const iframeBaseUrl = `${PDF_BASE_URL}/${props.pdfId}`;
  const currentDisplayPDFPageLink = `${iframeBaseUrl}/${curPage}`;
  const query = {pdfId: props.pdfId, pageNum: curPage};
  const searchString = qs.stringify(query);
  return (
      <div className='d-flex flex-column h-100'>
          <Slider value={curPage} onChange={(e, value) => setCurPage(value)}
              valueLabelDisplay="on" min={1} max={props.totalPages} />

          <div className="text-center mt-3">
            <h5>{props.name}</h5>
          </div>
          <div className="mb-3" style={{ textAlign: 'right' }}>
            {pageViews?<div>Page Views: {pageViews}</div>:<div>Page Views:</div>}
          </div>
          <div className={classes.actionContainer}>            
              <button className="btn-html-viewer" onClick={() => onOpen()}>
                  <span className='me-2'>Open</span>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
              </button>
              <button className="btn-html-viewer" onClick={() => onThumbnail()}>
                  <span className='me-2'>Thumbnail</span>
                  <FontAwesomeIcon icon={faThumbTack} />
              </button>
              <button className="btn-html-viewer" onClick={() => onEdit()}>
                  <span className='me-2'>Edit</span>
                  <FontAwesomeIcon icon={faPencil} />
              </button>
              <button onClick={() => props.onDelete(props.pdfId)} className="btn-html-viewer">
                  <span className='me-2'>Delete</span>
                  <FontAwesomeIcon icon={faTrash} />                    
              </button>
              {/* <button onClick={() => onGenerateAudio(props.pdfId)} 
                className={`btn-html-viewer ${audioGenerated===false?'':'disabled'}`}> */}
              <button onClick={() => setGenerateAudioModal(true)} 
                className={`btn-html-viewer`}>
                  <span className='me-2'>Generate Audio</span>
                  <FontAwesomeIcon icon={faVolumeHigh} />                    
              </button>
              <button onClick={() => onMerge()} 
                className={`btn-html-viewer ${isMergeAvailable()?'':'disabled'}`} 
                disabled={!isMergeAvailable()}>
                <span className='me-2'>Merge</span>
                <FontAwesomeIcon icon={faObjectGroup} />                     
              </button>
              <button onClick={() => onUnmerge()} 
                className={`btn-html-viewer ${isUnmergeAvailable()?'':'disabled'}`} 
                disabled={!isUnmergeAvailable()}>
                <span className='me-2'>Unmerge</span>
                <FontAwesomeIcon icon={faObjectUngroup} />                     
              </button>
          </div>

          <iframe title="pdf-iframe" id="iframe" width="100%" className="flex-grow-1"
              key={loadingMerge} srcDoc={htmlContent} />

          <Dialog onClose={() => setThumbnailModal(false)} aria-labelledby="simple-dialog-title" open={thumbnailModal}>
            <DialogTitle style={{ borderBottom:'1px solid #DDD'}}>Thumbnail</DialogTitle>
            <div className='p-3'>
              <div>
                <label className="form-label">Title</label>
                <input type="text" className="form-control" 
                    placeholder="Introduce Title"
                    value={thumbnail.title}
                    onChange={(e) => setThumbnail({...thumbnail, title: e.target.value})}></input>                             
              </div>
              <div className='mt-3'>
                <label className="form-label">Text</label>
                <textarea cols="50" rows="5" className="form-control" 
                    placeholder="Introduce Text"
                    value={thumbnail.text}
                    onChange={(e) => setThumbnail({...thumbnail, text: e.target.value.slice(0, 500)})}></textarea>
                <small> {thumbnail.text.length} / 500 characters</small>
              </div>
              <div className='mt-3'>
                <label className="form-label">Link</label>
                <input type="text" className="form-control" 
                    placeholder="Introduce image link"
                    value={thumbnail.img}
                    onChange={(e) => setThumbnail({...thumbnail, img: e.target.value})}>
                </input>      
                <div className='mt-2 text-center'>
                  <img src={thumbnail.img} alt="thumbnail" style={{ width: '200px'}}></img>                       
                </div>
              </div>
              <div className='mt-3 d-flex justify-content-end pb-3'>
                <button onClick={() => setThumbnailModal(false)} 
                  className={`btn-html-viewer`} >
                  <span className='m-2'>Close</span>
                </button>
                <button onClick={() => onSaveThumbnail()} 
                  className={`btn-html-viewer ms-3`}>
                  <span className='m-2'>Save</span>
                </button>
              </div>
            </div>

          </Dialog>

          <Dialog aria-labelledby="simple-dialog-title" open={generateAudioModal}>
            <DialogTitle style={{ borderBottom:'1px solid #DDD'}}>Generate Audio</DialogTitle>
            <div className='p-3'>

              <Select
                value={languageCode}
                label="Age"
                autoWidth
                onChange={(e) => onChangeLanguageCode(e)}
                disabled={false}
                style={{ width: '100%'}}>
                {languageList.map( (item, idx) => 
                  <MenuItem key={idx} value={item.LanguageCode}>{item.LanguageName}</MenuItem>
                )}
              </Select>

              <Select className='mt-3'
                value={voiceId}
                autoWidth
                onChange={(e) => setVoiceId(e.target.value)}
                disabled={false}
                style={{ width: '100%'}}>
                {voiceList.map( (item, idx) => 
                  <MenuItem key={idx} value={item}>{item}</MenuItem>
                )}
              </Select>
              
              {audioGenerationStatus!==AUDIO_GENERATION_STATUS.COMPLETED?
              <div>              
                <div className='mt-3'>Audio not generated</div>
              </div>
              :null}
              {audioGenerationStatus===AUDIO_GENERATION_STATUS.COMPLETED?
              <div>                
                <div className='mt-3'>Audio has been generated</div>
              </div>
              :null}

              <div className='mt-3 d-flex justify-content-end pb-3'>
                <button onClick={() => onGenerateAudio()} 
                  className={`btn-html-viewer d-flex align-items-center`}>                    
                  <span className='m-2'>Generate</span>
                </button>
                <button onClick={() => setGenerateAudioModal(false)} 
                  className={`btn-html-viewer d-flex align-items-center ms-3`} 
                  disabled={audioGenerationStatus!==AUDIO_GENERATION_STATUS.COMPLETED}>
                  <span className='m-2'>Ok</span>
                  {audioGenerationStatus === AUDIO_GENERATION_STATUS.ONGOING?
                    <CircularProgress size={"1.2em"} style={{ color: 'black'}} />
                  :null}
                </button>
              </div>
            </div>

          </Dialog>
      </div>
  );
};

export default HTMLViewer;

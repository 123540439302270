import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Login from './pages/Auth/Login';
import { ExtractPDF, HTMLEditor } from './pages/ExtractPDF';

import { useAuth } from './contexts/AuthContext';

function App() {
  const { auth } = useAuth();

  const isAuthorized = !!auth.token;

  return (
    <Router>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route>
            <Login />
          </Route>
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/" />
        )}
        {!isAuthorized ? (
          /* Check if request came from publish.apazine.com */
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Switch>
            <Route exact path="/" component={ExtractPDF} />
            <Route path="/edit" component={HTMLEditor} />
          </Switch>
        )}
      </Switch>

      <Toaster position="top-center" />
    </Router>
  );
}

export default App;
